import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
// SVG
import {ArrowRoundedUp13x8Svg} from "../../svg";

export default function ToTop() {
    const [show, setShow] = useState<boolean>(false);

    const showFrom = 300;
    const classes = classNames('toTop', {
        'toTop--show': show,
    });

    const onClick = () => {
        try {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } catch {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        let state = false;
        const onScroll = () => {
            const newState = window.pageYOffset >= showFrom;

            if (state !== newState) {
                setShow(state = newState);
            }
        };

        window.addEventListener('scroll', onScroll, {passive: true});

        // @ts-ignore
        return () => window.removeEventListener('scroll', onScroll, {passive: true});
    }, [setShow]);

    return (
        <div className={classes}>
            <div className="toTop__body">
                <div className="toTop__end">
                    <button type="button" className="toTop__button" onClick={onClick}>
                        <ArrowRoundedUp13x8Svg/>
                    </button>
                </div>
            </div>
        </div>
    );
}

